import request from '@/utils/request'

export function getSKUOptions(data, signal) {
  return request({
    url: `${process.env.VUE_APP_OMS_API}ofc-api-rest/inventory/selectSku`,
    method: 'post',
    signal,
    data
  })
}
