<template>
  <div>
    <el-dialog
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="title"
      :visible.sync="visible"
      center
      top="5vh"
      width="768px"
    >
      <!--商品信息-->
      <el-descriptions :title="$t('order.Productinformation')" />
      <el-form ref="form" :model="form" :rules="rules" label-width="auto" size="small">
        <el-row>
          <!--平台/站点/店铺-->
          <el-col :span="12">
            <el-form-item :label="$t('page.PlatformSiteShop')" prop="platformSiteShop">
              <el-cascader
                ref="platformSiteShop"
                v-model="form.platformSiteShop"
                :disabled="!isAdd || isDetail"
                :options="platformSiteShopOptions"
                :placeholder="$t('page.selectPlaceholder')"
                filterable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="SKU">
              <el-select
                ref="sku"
                v-model="form.platformProductIdList"
                :collapse-tags="collapseTags"
                :disabled="(!isAdd && !form.platformProductIdList.length)"
                :placeholder="$t('page.selectPlaceholder')"
                clearable
                multiple
                @click.native.capture="handleOpenQuerySkuDialog"
              >
                <el-option
                  v-for="item in skuOptions"
                  :key="item.sku"
                  :label="item.sku"
                  :value="item.productId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <!--平台虚拟仓-->
          <el-col :span="12">
            <el-form-item v-if="virtualWarehouseOptions.length" :label="$t('page.PlatformVirtualWarehouse')" prop="virtualWareId">
              <el-select
                v-model="form.virtualWareId"
                :disabled="!isAdd || isDetail"
                :placeholder="$t('page.selectPlaceholder')"
                clearable
                filterable
              >
                <el-option
                  v-for="item in virtualWarehouseOptions"
                  :key="item.id"
                  :label="item.virtualName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!--每日定时推送-->
      <el-descriptions v-if="!isDetail" :title="$t('page.DailyPush')" />
      <vxe-table
        v-if="!isDetail"
        key="dailyPush"
        :data="dailyPushTableData"
        :loading="queryLoading"
        :scroll-x="{ enabled: false }"
        :scroll-y="{ enabled: false }"
        :show-overflow="false"
        align="center"
        border
        max-height="200"
        size="small"
        style="width: 500px"
      >
        <!--序号-->
        <vxe-column :title="$t('page.No')" type="seq" />
        <!--时间节点-->
        <vxe-column :title="$t('page.PointInTime')">
          <template v-slot="{row}">
            <el-time-picker
              v-model="row.time"
              :disabled="isDetail"
              :placeholder="$t('page.selectPlaceholder')"
              size="small"
              value-format="HH:mm:ss"
            />
          </template>
        </vxe-column>
        <!--操作-->
        <vxe-column :title="$t('page.operate')">
          <template v-slot="{rowIndex}">
            <i
              v-if="!isDetail"
              class="el-icon-delete"
              style="cursor: pointer;color: #409EFF;font-size: 24px;"
              @click="handleDelByType('dailyPush', rowIndex)"
            />
          </template>
        </vxe-column>
      </vxe-table>
      <el-button
        v-if="!isDetail"
        icon="el-icon-circle-plus-outline"
        style="margin: 16px 0"
        type="primary"
        @click="handleAddByType('dailyPush')"
      >
        {{ $t('title.Add') }}
      </el-button>
      <!--推送规则-->
      <el-descriptions>
        <template #title>
          <span>{{ $t('page.PushRules') }}</span>
          <span style="color: #F56C6C">{{ $t('page.StockTip') }}</span>
        </template>
      </el-descriptions>
      <vxe-table
        key="pushRules"
        ref="table"
        :data="rulesTableData"
        :edit-config="{ trigger: 'manual', mode: 'row', autoClear: false, showIcon: false }"
        :loading="queryLoading"
        :merge-cells="mergeCells"
        :scroll-x="{ enabled: false }"
        :scroll-y="{ enabled: false }"
        :show-overflow="false"
        align="center"
        border
        max-height="400"
        size="small"
      >
        <!--履约仓库-->
        <vxe-column :edit-render="{}" :title="$t('page.ComplianceWarehouse')">
          <template #edit="{row}">
            <el-select v-model="row.wareHouseId" clearable filterable size="small">
              <el-option
                v-for="item in complianceWarehouseOptions"
                :key="item.id"
                :label="item.wareCode"
                :value="item.id"
              />
            </el-select>
          </template>
          <template #default="{row}">
            <span>{{ getComplianceWarehouseCode(row) }}</span>
          </template>
        </vxe-column>
        <!--履约仓库存量-->
        <vxe-column :edit-render="{}" :title="$t('page.StockOfComplianceWarehouse')" width="300">
          <template #edit="{row}">
            <el-input
              v-model="row.minNum"
              :placeholder="$t('page.inputPlaceholder')"
              clearable
              size="small"
              style="width: 100px"
            />
            ≤
            {{ $t('page.Stock') }}
            ＜
            <el-select
              v-model="row.maxNum"
              :placeholder="$t('page.inputPlaceholder')"
              allow-create
              clearable
              default-first-option
              filterable
              size="small"
              style="width: 100px"
            >
              <el-option :value="Infinity" label="无穷" />
            </el-select>
          </template>
          <template v-slot="{row}">
            {{ row.minNum }}  ≤ 库存 ＜ {{ row.maxNum === Infinity ? '无穷' : row.maxNum }}
          </template>
        </vxe-column>
        <!--推送库存量-->
        <vxe-column :edit-render="{}" :title="$t('page.PushStockLevels')" field="uploadNum" width="180">
          <template #edit="{row}">
            <el-input
              v-model="row.uploadNum"
              :placeholder="$t('page.inputPlaceholder')"
              clearable
              size="small"
              style="width: 160px"
            >
              <template #append>
                <el-select
                  v-model="row.uploadType"
                  allow-create
                  default-first-option
                  filterable
                  style="width: 68px;"
                >
                  <el-option :value="false" label="个" />
                  <el-option :value="true" label="%" />
                </el-select>
              </template>
            </el-input>
          </template>
          <template #default="{row}">
            <span>{{ row.uploadNum }}</span><span>{{ row.uploadType ? '%' : '个' }}</span>
          </template>
        </vxe-column>
        <!--操作-->
        <vxe-column v-if="!isDetail" :title="$t('page.operate')" width="120px">
          <template v-slot="{row, rowIndex}">
            <template v-if="$refs.table.isActiveByRow(row)">
              <i
                class="el-icon-check"
                style="cursor: pointer;color: #409EFF;font-size: 24px;width: 40px;"
                @click="handleSave"
              />
            </template>
            <template v-else>
              <i
                class="el-icon-edit-outline"
                style="cursor: pointer;color: #409EFF;font-size: 24px;width: 40px;"
                @click="handleEdit(row)"
              />
              <i
                class="el-icon-delete"
                style="cursor: pointer;color: #409EFF;font-size: 24px;width: 40px;"
                @click="handleDelByType('pushRules', rowIndex)"
              />
            </template>
          </template>
        </vxe-column>
      </vxe-table>
      <el-button
        v-if="!isDetail"
        icon="el-icon-circle-plus-outline"
        style="margin-top: 16px"
        type="primary"
        @click="handleAddByType('pushRules')"
      >
        {{ $t('title.Add') }}
      </el-button>
      <template v-if="!isDetail" #footer>
        <el-button @click="handleClose">{{ $t('order.Cancel') }}</el-button>
        <el-button type="primary" @click="handleSubmit">{{ $t('page.sure') }}</el-button>
      </template>
    </el-dialog>
    <query-s-k-u-dialog
      :is-detail="isDetail"
      :platform-site-shop="form.platformSiteShop"
      :platform-site-shop-ref="$refs.platformSiteShop"
      :sku-options="skuOptions"
      :style-id-list="styleIdList"
      :style-name="isAdd ? '' : row.style"
      :virtual-ware-id="form.virtualWareId"
      :visible.sync="querySKUDialogVisible"
      @select-sku="handleSelectSku"
    />
  </div>
</template>

<script>
import QuerySKUDialog from '../sellable-inventory/QuerySKUDialog.vue'
import { getVirtualWarehouseList } from '../apis'
import { addRules, updateRules } from './apis/list'
import { getComplianceWarehouseListByQuery, getRuleDetail } from './apis/addRulesDialog'
import { findValueByLabel } from '@/utils'
import { getPlatformSiteShopByUserId } from '../sellable-inventory/apis/list'

export default {
  name: 'AddRulesDialog',
  components: {
    QuerySKUDialog
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: 'add'
    }
  },
  data() {
    return {
      styleIdList: [],
      skuOptions: [],
      platformSiteShopOptions: [],
      styleOptions: [],
      virtualWarehouseOptions: [],
      complianceWarehouseOptions: [],
      collapseTags: true,
      form: {
        platformSiteShop: [],
        platformProductIdList: [],
        virtualWareId: ''
      },
      rules: {
        platformSiteShop: [
          { required: true, message: this.$t('page.selectPlaceholder'), trigger: 'change' }
        ],
        virtualWareId: [
          { required: true, message: this.$t('page.selectPlaceholder'), trigger: 'change' }
        ]
      },
      queryLoading: false,
      dailyPushTableData: [],
      rulesTableData: [],
      mergeCells: [],
      querySKUDialogVisible: false
    }
  },
  computed: {
    isDetail() {
      return this.type === 'detail'
    },
    isAdd() {
      return !this.row
    },
    title() {
      return this.isDetail ? this.$t('page.ViewPushRules') : this.isAdd ? this.$t('page.AddRules') : this.$t('page.EditRules')
    },
    submitFn() {
      return this.isAdd ? addRules : updateRules
    },
    submitParams() {
      const selected = this.$refs.platformSiteShop?.getCheckedNodes()?.[0]
      const [plat, site, shop] = selected?.pathLabels || []
      return {
        ...this.form,
        platformSiteShop: undefined,
        plat,
        site,
        shop,
        platformProductIdList: this.form.platformProductIdList,
        ruleTimeList: this.dailyPushTableData.map(e => e.time),
        ruleDetailList: this.rulesTableData.map(e => {
          return {
            ...e,
            minNum: +e.minNum,
            maxNum: e.maxNum === Infinity ? null : +e.maxNum
          }
        }),
        allIn: !this.form.platformProductIdList.length
      }
    }
  },
  watch: {
    'form.platformSiteShop'() {
      this.form.platformProductIdList = []
      this.form.virtualWareId = ''
      this.$nextTick(async() => {
        await Promise.all([this.getComplianceWarehouseList(), this.getVirtualWarehouseList()])
        this.clearComplianceWarehouse()
        if (this.isAdd) {
          this.form.platformProductIdList = []
          this.styleIdList = []
          this.skuOptions = []
          this.resetSkuSelectHeight()
        }
      })
    },
    'form.virtualWareId'() {
      this.form.platformProductIdList = []
      this.$nextTick(async() => {
        await this.getComplianceWarehouseList()
        this.clearComplianceWarehouse()
      })
    },
    visible(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.platformSiteShop.filterHandler()
        })
        if (!this.isAdd) {
          this.getRuleDetail()
        }
      } else {
        this.resetSkuSelectHeight()
      }
    }
  },
  created() {
    this.getPlatformSiteShopOptions()
  },
  methods: {
    clearComplianceWarehouse() {
      this.rulesTableData = this.rulesTableData.map(e => ({
        ...e,
        wareHouseId: this.complianceWarehouseOptions.find(item => item.id === e.wareHouseId)?.id ?? null
      }))
      this.handleMergeWarehouse()
    },
    resetSkuSelectHeight() {
      this.collapseTags = false
      this.$nextTick(() => {
        this.$refs.sku.resetInputHeight()
        this.collapseTags = true
      })
    },
    async getVirtualWarehouseList() {
      if (!this.form.platformSiteShop.length) {
        return
      }
      const { plat, site, shop } = this.submitParams
      const { datas } = await getVirtualWarehouseList({
        plat,
        site,
        shop
      })
      this.virtualWarehouseOptions = datas || []
    },
    async getRuleDetail() {
      this.queryLoading = true
      const { datas } = await getRuleDetail(this.row?.id).finally(() => {
        this.queryLoading = false
      })
      this.form.id = this.row.id
      const platformId = findValueByLabel(this.platformSiteShopOptions, datas?.plat)
      const siteId = findValueByLabel(this.platformSiteShopOptions, datas?.site)
      const shop = findValueByLabel(this.platformSiteShopOptions, datas?.shop)
      this.form.platformSiteShop = [platformId, siteId, shop]
      this.$refs.platformSiteShop.filterHandler()
      this.skuOptions = datas?.ruleSkuList.map(e => ({ sku: e.sku, productId: e.platformProductId }))
      this.dailyPushTableData = datas?.executeTimeList.map(e => ({ time: e }))
      this.rulesTableData = datas?.ruleDetailList.map(e => {
        return {
          ...e,
          maxNum: e.maxNum ?? Infinity,
          wareHouseId: e.wareHouseId ?? ''
        }
      })
      this.$nextTick(async() => {
        this.form.virtualWareId = datas?.virtualWareId
        await this.$nextTick()
        this.form.platformProductIdList = datas?.ruleSkuList.map(e => e.platformProductId) || []
        this.resetSkuSelectHeight()
      })
      this.handleMergeWarehouse()
    },
    handleSelectSku({ sku, style }) {
      this.form.platformProductIdList = sku.map(e => e.productId)
      this.skuOptions = sku
      this.styleIdList = style
      this.resetSkuSelectHeight()
    },
    handleSave() {
      this.$refs.table.clearActived()
      this.handleMergeWarehouse()
    },
    handleEdit(row) {
      this.mergeCells = []
      this.$refs.table.setActiveRow(row)
    },
    getComplianceWarehouseCode(row) {
      if (row.wareHouseId) {
        return this.complianceWarehouseOptions.find(e => e.id === row.wareHouseId)?.wareCode || row.wareHouseId
      } else {
        if (row.wareHouseId === '') {
          return this.$t('title.OrderAll')
        }
        return this.$t('page.selectPlaceholder')
      }
    },
    async getComplianceWarehouseList() {
      this.complianceWarehouseOptions = []
      if (!this.form.platformSiteShop.length) {
        return
      }
      const { plat, site, shop, virtualWareId } = this.submitParams
      const { datas } = await getComplianceWarehouseListByQuery({
        plat,
        site,
        shop,
        virtualHouseId: virtualWareId
      })
      if (datas?.length) {
        this.complianceWarehouseOptions = datas.concat([{ id: '', wareCode: this.$t('title.OrderAll') }])
      } else {
        this.complianceWarehouseOptions = []
      }
    },
    async getPlatformSiteShopOptions() {
      const { datas } = await getPlatformSiteShopByUserId()
      this.platformSiteShopOptions = datas || []
    },
    handleOpenQuerySkuDialog(e) {
      if (!this.isAdd && !this.form.platformProductIdList.length) {
        return
      }
      if (e.target.tagName === 'INPUT' || e.target.className.includes('el-select__tags')) {
        e.stopPropagation()
        this.$refs.form.validate(valid => {
          if (valid) {
            this.querySKUDialogVisible = true
          }
        })
      }
    },
    handleAddByType(type) {
      if (type === 'dailyPush') {
        this.dailyPushTableData.push({ time: '' })
      } else {
        this.rulesTableData.push({
          uploadType: false,
          wareHouseId: '',
          uploadNum: '',
          minNum: '',
          maxNum: ''
        })
        this.handleMergeWarehouse()
        setTimeout(() => {
          this.mergeCells = []
          this.$refs.table.setActiveRow(this.rulesTableData.at(-1))
        })
      }
    },
    handleDelByType(type, index) {
      if (type === 'dailyPush') {
        this.dailyPushTableData.splice(index, 1)
      } else {
        this.rulesTableData.splice(index, 1)
        this.handleMergeWarehouse()
      }
    },
    handleMergeWarehouse() {
      const mergeFields = ['wareHouseId']
      const mergeCells = []
      const set = new Set()
      let list = []
      this.rulesTableData.map((row, rowIndex) => {
        const { wareHouseId } = row
        if (set.has(wareHouseId)) return
        const same = this.rulesTableData.filter(e => e.wareHouseId === wareHouseId)
        const len = same.length
        same.sort((a, b) => {
          if (!a.minNum) return 0
          return a.minNum - b.minNum
        })
        list = list.concat(same.map((item, i) => ({
          ...item,
          rowspan: i === 0 ? len : 0,
          colspan: i === 0 ? 1 : 0
        })))
        set.add(wareHouseId)
      })
      list.forEach((item, row) => {
        if (item.rowspan > 1) {
          mergeFields.forEach((field, col) => {
            if (field) {
              mergeCells.push({
                row,
                col,
                rowspan: item.rowspan,
                colspan: item.colspan
              })
            }
          })
        }
      })
      this.rulesTableData = list
      this.mergeCells = mergeCells
    },
    handleClose() {
      this.form = {
        platformSiteShop: [],
        platformProductIdList: [],
        virtualWareId: ''
      }
      this.dailyPushTableData = []
      this.rulesTableData = []
      this.virtualWarehouseOptions = []
      this.skuOptions = []
      this.styleIdList = []
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
      this.$emit('update:row', null)
      this.$emit('update:visible', false)
    },
    isContinuous() {
      const wares = {}
      this.rulesTableData.forEach(e => {
        if (wares[e.wareHouseId]) {
          wares[e.wareHouseId].push({ minNum: +e.minNum, maxNum: +e.maxNum })
        } else {
          wares[e.wareHouseId] = [{ minNum: +e.minNum, maxNum: +e.maxNum }]
        }
      })
      const wareNameList = []
      const isContinuous = Object.entries(wares).every(([ware, arr]) => {
        arr.sort((a, b) => a.minNum - b.minNum)
        // 检查分组中的最小值是否为0，最大值是否为正无穷
        const wareName = this.complianceWarehouseOptions.find(e => +e.id === +ware)?.wareCode
        if (arr[0].minNum !== 0 || arr[arr.length - 1].maxNum !== Infinity) {
          wareNameList.push(wareName)
          return false
        }
        // 遍历这个数组，检查每两个相邻的元素之间的差值是否大于0
        for (let i = 0; i < arr.length - 1; i++) {
          if (arr[i].maxNum !== arr[i + 1].minNum) {
            wareNameList.push(wareName)
            return false
          }
        }
        // 如果上面的循环没有返回false，那么这个数组就是正连续的
        return true
      })
      if (!isContinuous) {
        this.$message.warning(this.$t('page.notContinuous', { warehouse: wareNameList.join('、') }))
      }
      return isContinuous
    },
    validate() {
      return new Promise(resolve => {
        this.$refs.form.validate(valid => {
          if (!valid) {
            resolve(false)
          } else {
            if (!this.dailyPushTableData.length || !this.rulesTableData.length) {
              this.$message.warning(this.$t('page.inputTip'))
              resolve(false)
              return
            }
            const res = this.rulesTableData.every(row => {
              if (row.wareHouseId === null) {
                this.$message.warning(this.$t('page.inputTip'))
                return false
              }
              if (this.$refs.table.isActiveByRow(row)) {
                this.$message.warning(this.$t('title.savefirst'))
                return false
              }
              if (isNaN(row.minNum) || isNaN(row.maxNum) || isNaN(row.uploadNum)) {
                this.$message.warning(this.$t('tips.InputNumber'))
                return false
              }
              // 推送类型为个的时候判断不能大于区间最小值
              if (+row.uploadNum > +row.minNum && row.uploadType === false) {
                this.$message.warning(this.$t('tips.GreaterThanMin'))
                return false
              }
              if (+row.minNum < 0) {
                this.$message.warning(this.$t('tips.GreaterThan0'))
                return false
              }
              if (!(typeof row.uploadType !== 'undefined' && (row.uploadNum || row.uploadNum === 0) && +row.minNum < +row.maxNum)) {
                this.$message.warning(this.$t('page.inputTip'))
                return false
              }
              return true
            })
            if (!res) {
              resolve(false)
              return
            }
            const hasAllWarehouse = this.rulesTableData.find(e => e.wareHouseId === '')
            if (hasAllWarehouse && this.rulesTableData.some(e => e.wareHouseId)) {
              this.$message.warning(this.$t('page.allWarehouseTip'))
              resolve(false)
              return
            }
            const isContinuous = this.isContinuous()
            if (!isContinuous) {
              resolve(false)
              return
            }
            resolve(true)
          }
        })
      })
    },
    async handleSubmit() {
      const valid = await this.validate()
      if (!valid) {
        return
      }
      this.$confirm(this.$t('title.batchprocessing'), this.$t('page.Prompt'), {
        closeOnClickModal: false,
        type: 'warning',
        beforeClose: async(action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = instance.cancelButtonLoading = true
            const { code, msg } = await this.submitFn(this.submitParams).finally(() => {
              instance.confirmButtonLoading = instance.cancelButtonLoading = false
            })
            if (code === 0) {
              this.$message.success(msg)
              this.$emit('refresh')
              done()
              this.handleClose()
            }
          } else {
            done()
          }
        }
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
::v-deep .el-date-editor.el-input {
    width: 100%;
}
</style>
