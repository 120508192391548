import request from '@/utils/request'

export function getAutoPushRuleList(data, signal) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/rulePrime/page`,
    method: 'get',
    data,
    signal
  })
}

export function addRules(data) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/rulePrime/add`,
    method: 'post',
    data
  })
}

export function updateRules(data) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/rulePrime/update`,
    method: 'post',
    data
  })
}

export function updateRulesStatus(data) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/rulePrime/updateStatus`,
    method: 'post',
    data
  })
}
