import request from '@/utils/request'

export function getRuleDetail(id) {
  return request({
    url: `${process.env.VUE_APP_OMS_API}ofc-api-rest/rulePrime/detail/${id}`
  })
}

export function getComplianceWarehouseListByQuery(params) {
  return request({
    url: `${process.env.VUE_APP_OMS_API}ofc-api-rest/rulePrime/listWarehouse`,
    params
  })
}
