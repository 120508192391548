<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" inline label-width="0" size="small">
      <el-form-item label=" " prop="allIn">
        <el-select v-model="form.allIn" clearable placeholder="规则粒度">
          <el-option :value="false" label="配置到SKU" />
          <el-option :value="true" label="配置到店铺" />
        </el-select>
      </el-form-item>
      <el-form-item label=" " prop="plat">
        <el-select
          v-model="form.plat"
          :placeholder="$t('title.PlatForm')"
          clearable
          filterable
        >
          <el-option
            v-for="item in platformList"
            :key="item.platformId"
            :label="item.platformName"
            :value="item.platformId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="site">
        <el-select
          v-model="form.site"
          :placeholder="$t('title.site')"
          clearable
          collapse-tags
          filterable
          multiple
        >
          <el-option
            v-for="item in siteList"
            :key="item.siteId"
            :label="item.siteName"
            :value="item.siteId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="shop">
        <el-select
          v-model="form.shop"
          :placeholder="$t('title.shopName')"
          clearable
          collapse-tags
          filterable
          multiple
        >
          <el-option
            v-for="item in shopList"
            :key="item.shopId"
            :label="item.shopName"
            :value="item.shopId"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.allIn === false">
        <el-select
          v-model="form.styleList"
          clearable
          collapse-tags
          filterable
          multiple
          placeholder="Style"
        >
          <el-option
            v-for="item in styleOptions"
            :key="item.styleId"
            :label="item.style"
            :value="item.styleId"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.allIn === false">
        <el-select
          v-model="form.skuList"
          clearable
          collapse-tags
          filterable
          multiple
          placeholder="SKU"
        >
          <el-option
            v-for="item in skuOptions"
            :key="item.id"
            :label="item.sku"
            :value="item.sku"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="virtualWarehouseOptions.length">
        <el-select
          v-model="form.virtualHouseIdList"
          :placeholder="$t('page.PlatformVirtualWarehouse')"
          clearable
          collapse-tags
          filterable
          multiple
        >
          <el-option
            v-for="item in virtualWarehouseOptions"
            :key="item.id"
            :label="item.virtualName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button :loading="queryLoading" type="primary" @click="handleQuery">
          {{ $t('page.search') }}
        </el-button>
        <el-button @click="handleReset">
          {{ $t('page.reset') }}
        </el-button>
      </el-form-item>
    </el-form>
    <vxe-toolbar>
      <template #buttons>
        <el-button v-permission="'add-rules'" type="primary" @click="handleOpenAddRulesDialog()">{{ $t('page.AddRules') }}</el-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="table"
      :data="tableData"
      :loading="queryLoading"
      :max-height="maxHeight"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      border
      resizable
    >
      <vxe-column :title="$t('page.PlatformSiteShop')" show-overflow width="140">
        <template v-slot="{row}">
          {{ getPlatformSiteShop(row) }}
        </template>
      </vxe-column>
      <vxe-column field="sku" title="SKU" width="300">
        <template v-slot="{row}">
          <el-tooltip>
            <template v-if="row.sku" #content>
              <div v-for="(item) in row.sku.split(',')" :key="item">
                <span>{{ item }}</span>
                <br>
              </div>
            </template>
            <div>
              <div v-if="row.sku">
                <div v-for="(item, index) in row.sku.split(',')" :key="item">
                  <span v-if="index < 5">{{ item }}</span>
                  <span v-if="index === 5">...</span>
                </div>
              </div>
            </div>
          </el-tooltip>
        </template>
      </vxe-column>
      <vxe-column field="sellSku" show-overflow title="MSKU" width="100" />
      <vxe-column field="style" title="Style" width="140" />
      <vxe-column field="operator" title="Operator" width="120" />
      <vxe-column field="color" min-width="60" title="Color" />
      <vxe-column field="size" min-width="60" title="Size" />
      <!--平台虚拟仓-->
      <vxe-column :title="$t('page.PlatformVirtualWarehouse')" field="virtualName" width="100px" />
      <!--定时推送(北京时间)-->
      <vxe-column :title="$t('page.TimedPush')" field="executeTime" width="90">
        <template v-slot="{row}">
          {{ row.executeTime ? row.executeTime.split(',').join('\n') : '' }}
        </template>
      </vxe-column>
      <!--创建时间-->
      <vxe-column :title="$t('page.createTime')" field="createTime" width="100" />
      <!--状态-->
      <vxe-column :title="$t('page.status')" width="80">
        <template v-slot="{row}">
          {{ row.status ? '启用' : '禁用' }}
        </template>
      </vxe-column>
      <vxe-column :title="$t('page.operate')" fixed="right" width="100">
        <template v-slot="{row}">
          <el-row align="middle" justify="start" style="flex-wrap: wrap" type="flex">
            <el-col :span="24">
              <el-button type="text" @click="handleOpenAddRulesDialog(row, 'detail')">{{ $t('page.ViewPushRules') }}</el-button>
            </el-col>
            <el-col v-permission="'add-rules'" :span="12">
              <el-switch
                :value="row.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="val => handleUpdateStatus(row, val)"
              />
            </el-col>
            <el-col v-permission="'add-rules'" :span="12">
              <el-button type="text" @click="handleOpenAddRulesDialog(row)">{{ $t('title.Edit') }}</el-button>
            </el-col>
          </el-row>
        </template>
      </vxe-column>
    </vxe-table>
    <paging ref="pager" :pager="pager" end @update="handlePagerUpdate" />
    <add-rules-dialog
      :row.sync="currentRow"
      :type="addRulesDialogType"
      :visible.sync="addRulesDialogVisible"
      @refresh="handleQuery"
    />
  </div>
</template>

<script>
import { getPlatformListByUser, getShopList, getSiteList, getSKUList, getStyleList } from '@/api/select'
import Paging from '@/components/Paging/index.vue'
import { debounceGetTableMaxHeight } from '@/utils'
import AddRulesDialog from './AddRulesDialog.vue'
import { getAutoPushRuleList, updateRulesStatus } from './apis/list'
import { getVirtualWarehouseList } from '../apis'

export default {
  name: 'AutoPushRulesList',
  components: {
    AddRulesDialog,
    Paging
  },
  data() {
    return {
      platformList: [],
      siteList: [],
      shopList: [],
      styleOptions: [],
      skuOptions: [],
      virtualWarehouseOptions: [],
      form: {
        allIn: '',
        plat: '',
        site: [],
        shop: [],
        styleList: [],
        skuList: [],
        virtualHouseIdList: []
      },
      rules: {
        allIn: [
          { required: true, message: this.$t('page.selectPlaceholder'), trigger: 'change' }
        ],
        plat: [
          { required: true, message: this.$t('page.selectPlaceholder'), trigger: 'change' }
        ]
      },
      queryLoading: false,
      maxHeight: 0,
      tableData: [],
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      currentRow: null,
      addRulesDialogVisible: false,
      addRulesDialogType: 'add'
    }
  },
  computed: {
    queryListParams() {
      const { plat, site, shop } = this.form
      return {
        platformId: plat ? [plat] : [],
        siteId: site,
        shopId: shop
      }
    },
    queryParams() {
      const { plat, site, shop } = this.form
      const platformName = this.platformList.find(e => e.platformId === plat)?.platformName
      const siteName = this.siteList.filter(e => site.includes(e.siteId)).map(e => e.siteName)
      const shopName = this.shopList.filter(e => shop.includes(e.shopId)).map(e => e.shopName)
      return {
        ...this.form,
        plat: platformName,
        site: siteName,
        shop: shopName,
        styleList: this.form.styleList.map(e => this.styleOptions.find(item => +item.styleId === e)?.style),
        pageNum: this.pager.current,
        pageSize: this.pager.size
      }
    }
  },
  watch: {
    'form': {
      handler() {
        this.queryChange = true
      },
      deep: true
    },
    'form.plat'() {
      this.form.site = []
      this.getSiteList()
    },
    'form.site'() {
      this.form.shop = []
      this.getShopList()
    },
    'form.shop'() {
      this.form.styleList = []
      this.form.virtualHouseIdList = []
      this.getStyleList()
      this.getVirtualWarehouseOptions()
    },
    'form.styleList': {
      handler() {
        this.form.skuList = []
        this.getSKUList()
      },
      deep: true
    }
  },
  created() {
    this.getPlatformList()
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this)
  },
  mounted() {
    this.debounceGetTableMaxHeight()
    window.addEventListener('resize', this.debounceGetTableMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    async handleUpdateStatus(row, status) {
      if (status === false && row.allIn) {
        this.$confirm(this.$t('title.batchprocessing'), this.$t('page.Prompt'), {
          closeOnClickModal: false,
          type: 'warning',
          beforeClose: async(action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = instance.cancelButtonLoading = true
              const { msg, code } = await updateRulesStatus({
                id: row.id,
                status
              }).finally(() => {
                instance.confirmButtonLoading = instance.cancelButtonLoading = false
              })
              if (code === 0) {
                done()
                this.$message.success(msg)
                this.handleQuery()
              }
            } else {
              done()
            }
          }
        }).catch(() => {

        })
        return
      }
      const { msg, code } = await updateRulesStatus({
        id: row.id,
        status
      })
      if (code === 0) {
        this.$message.success(msg)
        this.handleQuery()
      }
    },
    getPlatformSiteShop(row) {
      const { plat, site, shop } = row
      return `${plat}${site ? `/${site}` : ''}${shop ? `/${shop}` : ''} `
    },
    async getPlatformList() {
      const { datas } = await getPlatformListByUser({
        userId: ''
      })
      this.platformList = datas || []
    },
    async getSiteList() {
      const { platformId } = this.queryListParams
      if (!platformId.length) {
        this.siteList = []
        return
      }
      const { datas } = await getSiteList({
        platformId
      })
      this.siteList = datas || []
    },
    async getShopList() {
      const { platformId, siteId } = this.queryListParams
      if (!platformId.length || !siteId.length) {
        this.shopList = []
        return
      }
      const { datas } = await getShopList({
        platformId,
        siteId
      })
      this.shopList = datas || []
    },
    async getStyleList() {
      const { platformId, siteId, shopId } = this.queryListParams
      if (!platformId.length || !siteId.length || !shopId.length) {
        this.styleList = []
        return
      }
      const { data } = await getStyleList(this.queryListParams)
      this.styleOptions = data || []
    },
    async getSKUList() {
      if (!this.form.styleList.length) {
        this.skuOptions = []
        return
      }
      const { datas } = await getSKUList({
        styleIdList: this.form.styleList
      })
      this.skuOptions = datas || []
    },
    async getVirtualWarehouseOptions() {
      const { plat, site, shop } = this.queryParams
      if (!plat) {
        return
      }
      const { datas } = await getVirtualWarehouseList({
        plat,
        site,
        shop
      })
      this.virtualWarehouseOptions = datas || []
    },
    handleQuery() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.queryLoading = true
          if (this.controller) {
            this.controller.abort()
            setTimeout(() => {
              this.queryLoading = true
            })
          }
          this.controller = new AbortController()
          const signal = this.controller.signal
          if (this.queryChange) {
            this.pager.current = 1
          }
          const { datas } = await getAutoPushRuleList(this.queryParams, signal).finally(() => {
            this.queryLoading = this.queryChange = false
          })
          this.tableData = datas?.list || []
          this.pager.total = datas?.totalCount || 0
        }
      })
    },
    handleReset() {
      this.form = {
        allIn: '',
        plat: '',
        site: [],
        shop: [],
        styleList: [],
        skuList: [],
        virtualHouseIdList: []
      }
      this.$refs.form.clearValidate()
    },
    handleOpenAddRulesDialog(row = null, type = 'add') {
      this.addRulesDialogType = type
      this.currentRow = row
      this.addRulesDialogVisible = true
    },
    handlePagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
