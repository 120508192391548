<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="$t('page.SelectSKU')"
    :visible.sync="visible"
    top="5vh"
    width="705px"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="auto" size="small">
      <el-row :gutter="20">
        <el-col :span="10">
          <el-form-item label="Style" prop="styleIdList">
            <el-select
              v-model="form.styleIdList"
              :placeholder="$t('page.selectPlaceholder')"
              clearable
              collapse-tags
              filterable
              multiple
              @change="form.sku = []"
            >
              <el-option
                v-for="item in styleOptions"
                :key="item.styleId"
                :label="item.style"
                :value="item.styleId"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="Color">
            <el-select
              v-model="form.colorList"
              :placeholder="$t('page.selectPlaceholder')"
              clearable
              collapse-tags
              filterable
              multiple
            >
              <el-option
                v-for="item in colorOptions"
                :key="item.color"
                :label="item.color"
                :value="item.color"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label-width="0">
            <el-button :loading="queryLoading" type="primary" @click="handleQuery">
              {{ $t('page.search') }}
            </el-button>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="Size">
            <el-select
              v-model="form.sizeList"
              :placeholder="$t('page.selectPlaceholder')"
              clearable
              collapse-tags
              filterable
              multiple
            >
              <el-option
                v-for="item in sizeOptions"
                :key="item.size"
                :label="item.size"
                :value="item.size"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="SKU">
            <el-select
              v-model="form.sku"
              :placeholder="$t('page.selectPlaceholder')"
              clearable
              collapse-tags
              filterable
              multiple
            >
              <el-option
                v-for="item in skuList"
                :key="item.id"
                :label="item.sku"
                :value="item.sku"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <vxe-table
      ref="table"
      :checkbox-config="{ reserve: true }"
      :data="tableData"
      :loading="queryLoading"
      :max-height="maxHeight"
      :row-config="{ keyField:'sku' }"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      resizable
    >
      <vxe-column type="checkbox" width="40" />
      <vxe-column field="style" title="Style" />
      <vxe-column field="color" title="Color" />
      <vxe-column field="size" title="Size" />
      <vxe-column field="sku" title="SKU" />
      <vxe-column field="platSku" title="MSKU" />
    </vxe-table>
    <paging ref="pager" :pager="pager" end @update="handlePagerUpdate" />
    <template v-if="!isDetail" #footer>
      <el-button @click="handleClose">
        {{ $t('order.Cancel') }}
      </el-button>
      <el-button type="primary" @click="handleSubmit">
        {{ $t('page.sure') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { getColorListByStyleId, getSizeListByStyleId, getSKUList } from '@/api/select'
import { debounceGetTableMaxHeight } from '@/utils'
import Paging from '@/components/Paging/index.vue'
import { getSKUOptions } from './apis/querySKUDialog'
import { getStyleList } from '../sellable-inventory/apis/list'

export default {
  name: 'QuerySKUDialog',
  components: { Paging },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    platformSiteShop: {
      type: [Array, String],
      default: () => []
    },
    virtualWareId: {
      type: [Number, String],
      default: ''
    },
    platformSiteShopRef: {
      type: Object,
      default: null
    },
    skuOptions: {
      type: Array,
      default: () => []
    },
    styleName: {
      type: String,
      default: ''
    },
    styleIdList: {
      type: Array,
      default: () => []
    },
    isDetail: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      skuList: [],
      form: {
        styleIdList: [],
        colorList: [],
        sizeList: [],
        sku: []
      },
      rules: {
        styleIdList: [
          { required: true, message: this.$t('page.selectPlaceholder'), trigger: 'change' }
        ]
      },
      pager: {
        size: 500,
        current: 1,
        total: 0
      },
      maxHeight: 0,
      tableData: [],
      queryLoading: false
    }
  },
  computed: {
    styleParams() {
      const [platformId, siteId, shopId] = this.platformSiteShop || []
      return {
        platformId: platformId ? [platformId] : [],
        siteId: siteId ? [siteId] : [],
        shopId: shopId ? [shopId] : []
      }
    },
    queryParams() {
      const selected = this.platformSiteShopRef?.getCheckedNodes()?.[0]
      const [platformName, siteName, shopName] = selected?.pathLabels || []
      return {
        ...this.form,
        plat: platformName,
        site: siteName,
        shop: shopName,
        virtualHouseId: this.virtualWareId,
        color: this.form.colorList,
        size: this.form.sizeList,
        style: this.form.styleIdList.map(e => this.styleOptions.find(item => item.styleId === e)?.style).filter(e => e),
        currPage: this.pager.current,
        pageSize: this.pager.size
      }
    }
  },
  watch: {
    visible: {
      async handler(val) {
        if (val) {
          this.queryLoading = true
          this.debouncedGetTableMaxHeight()
          this.form.sku = this.skuOptions.map(e => e.sku)
          await this.getStyleList()
          if (this.styleName) {
            // 编辑时
            const styleNameList = this.styleName.split(',')
            this.form.styleIdList = this.styleOptions.filter(e => styleNameList.includes(e.style)).map(e => e.styleId)
            await this.handleQuery()
            setTimeout(() => {
              this.$refs.table.setAllCheckboxRow(true)
            })
          }
          if (this.styleIdList) {
            // 添加时
            this.form.styleIdList = this.form.styleIdList.concat(this.styleIdList)
            await this.handleQuery()
            setTimeout(() => {
              this.$refs.table.setAllCheckboxRow(true)
            })
          }
          this.queryLoading = false
        }
      }
    },
    'form': {
      handler() {
        this.queryChange = true
      },
      deep: true
    },
    'form.styleIdList': {
      handler(val) {
        this.sizeOptions = []
        this.colorOptions = []
        this.form.colorList = []
        this.form.sizeList = []
        if (val.length) {
          Promise.all([this.getColorList(), this.getSizeList()])
        }
      },
      deep: true
    },
    'form.colorList'() {
      this.getSKUList()
    },
    'form.sizeList'() {
      this.getSKUList()
    }
  },
  created() {
    this.debouncedGetTableMaxHeight = debounceGetTableMaxHeight.bind(this, {
      minHeight: 300,
      offsetBottom: 100
    })
  },
  methods: {
    async getSKUList() {
      if (!this.form.styleIdList.length) {
        this.skuList = []
        return
      }
      const { datas } = await getSKUList({
        styleIdList: this.form.styleIdList,
        colorList: this.form.colorList,
        sizeList: this.form.sizeList
      })
      this.skuList = datas || []
    },
    async getStyleList() {
      if (!this.styleParams.platformId.length || !this.styleParams.siteId.length || !this.styleParams.shopId.length) {
        this.styleList = []
        return
      }
      const { datas } = await getStyleList(this.styleParams)
      this.styleOptions = datas || []
    },
    async getColorList() {
      const { datas } = await getColorListByStyleId({
        styleIdList: this.form.styleIdList
      })
      this.colorOptions = datas || []
    },
    async getSizeList() {
      const { datas } = await getSizeListByStyleId({
        styleIdList: this.form.styleIdList
      })
      this.sizeOptions = datas || []
    },
    handleQuery() {
      return new Promise(resolve => {
        this.$refs.form.validate(async valid => {
          if (valid) {
            this.queryLoading = true
            if (this.controller) {
              this.controller.abort()
              setTimeout(() => {
                this.queryLoading = true
              })
            }
            this.controller = new AbortController()
            const signal = this.controller.signal
            if (this.queryChange) {
              this.pager.current = 1
            }
            const { datas } = await getSKUOptions(this.queryParams, signal).finally(() => {
              this.queryLoading = this.queryChange = false
              resolve()
            })
            this.tableData = datas?.list || []
            this.pager.total = datas?.totalCount || 0
            await Promise.all([this.$refs.table.clearCheckboxReserve(), this.$refs.table.clearCheckboxRow()])
            this.queryLoading = false
            resolve()
          } else {
            resolve()
          }
        })
      })
    },
    handlePagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    },
    handleClose() {
      this.form = {
        styleIdList: [],
        colorList: [],
        sizeList: [],
        sku: []
      }
      this.$refs.table.clearCheckboxReserve()
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
      this.tableData = []
      this.$emit('update:visible', false)
    },
    handleSubmit() {
      const tableRef = this.$refs.table
      const selectedRows = tableRef.getCheckboxRecords().concat(tableRef.getCheckboxReserveRecords())
      this.$emit('select-sku', {
        sku: selectedRows.map(item => ({ sku: item.sku, productId: item.productId })),
        style: this.form.styleIdList
      })
      this.handleClose()
    }
  }
}
</script>

<style scoped>

</style>
